import {useEffect, useState} from "react";

export default function dealTableData(infoData, equipData, adjValueLines) {

    const lookupData = [
        { key: 'Truck0', type: 'Truck', counter: 0, valueLine: 90 },
        { key: 'Truck1', type: 'Truck', counter: 1, valueLine: 75 },
        { key: 'Truck2', type: 'Truck', counter: 2, valueLine: 65 },
        { key: 'Truck3', type: 'Truck', counter: 3, valueLine: 60 },
        { key: 'Truck4', type: 'Truck', counter: 4, valueLine: 55 },
        { key: 'Truck5', type: 'Truck', counter: 5, valueLine: 50 },
        { key: 'Truck6', type: 'Truck', counter: 6, valueLine: 45 },
        { key: 'Truck7', type: 'Truck', counter: 7, valueLine: 40 },
        { key: 'Truck8', type: 'Truck', counter: 8, valueLine: 35 },
        { key: 'Truck9', type: 'Truck', counter: 9, valueLine: 30 },
        { key: 'Truck10', type: 'Truck', counter: 10, valueLine: 25 },
        { key: 'Truck11', type: 'Truck', counter: 11, valueLine: 20 },
        { key: 'Truck12', type: 'Truck', counter: 12, valueLine: 15 },
        { key: 'Truck13', type: 'Truck', counter: 13, valueLine: 10 },
        { key: 'Truck14', type: 'Truck', counter: 14, valueLine: 5 },
        { key: 'Truck15', type: 'Truck', counter: 15, valueLine: 0 },
        { key: 'Trailer0', type: 'Trailer', counter: 0, valueLine: 75 },
        { key: 'Trailer1', type: 'Trailer', counter: 1, valueLine: 60 },
        { key: 'Trailer2', type: 'Trailer', counter: 2, valueLine: 50 },
        { key: 'Trailer3', type: 'Trailer', counter: 3, valueLine: 45 },
        { key: 'Trailer4', type: 'Trailer', counter: 4, valueLine: 40 },
        { key: 'Trailer5', type: 'Trailer', counter: 5, valueLine: 35 },
        { key: 'Trailer6', type: 'Trailer', counter: 6, valueLine: 30 },
        { key: 'Trailer7', type: 'Trailer', counter: 7, valueLine: 25 },
        { key: 'Trailer8', type: 'Trailer', counter: 8, valueLine: 20 },
        { key: 'Trailer9', type: 'Trailer', counter: 9, valueLine: 15 },
        { key: 'Trailer10', type: 'Trailer', counter: 10, valueLine: 10 },
        { key: 'Trailer11', type: 'Trailer', counter: 11, valueLine: 5 },
        { key: 'Trailer12', type: 'Trailer', counter: 12, valueLine: 0 },
        { key: 'Trailer13', type: 'Trailer', counter: 13, valueLine: 0 },
        { key: 'Trailer14', type: 'Trailer', counter: 14, valueLine: 0 },
        { key: 'Trailer15', type: 'Trailer', counter: 15, valueLine: 0 },
    ];
    let rows = [];
    let graphData = {};
    const valueLines = [];
    const exposureData = [];
    const yearlyAverage = [];
    let totalPercentage = 0;
    let annualPer = null;
    let riskClassPd;
    let minTargetProfit;
    let opCosts;
    let otherCosts;
    let originalNil;
    let originalDownPay;
    let nil;
    let downPay;
    let paymentAmount;
    let time;
    let equipCost;
    let annualRate;
    //Funding Cost Variables
    let fundingCosts;
    let wacc;
    let senior;
    let subdebt;
    let equity;
    //notNeeded
    let feeIncome;
    let comCosts;
    // EquipData
    let assets = [];
    let assetType;
    let brand;
    let type;
    let mileage;
    let ageEquip;
    // let {riskClass, equipCost, downPay, downPayPer, nil, time, annualRate, fundingCosts, opCosts, feeIncome, comPer, comCosts} = infoData

    if (equipData) {
        equipData.forEach(item => {
            const tempAsset = {
                assetType: item.find(assetInfo => assetInfo && assetInfo.key === "Asset type")?.value,
                brand: item.find(assetInfo => assetInfo && assetInfo.key === "Brand")?.value,
                type: item.find(assetInfo => assetInfo && assetInfo.key === "Type")?.value,
                mileage: item.find(assetInfo => assetInfo && assetInfo.key === "Mileage")?.value,
                ageEquip: item.find(assetInfo => assetInfo && assetInfo.key === "Age equipment (years)")?.value,
            }
            assets.push(tempAsset);
        });
    }

    const handleIntInputChange = (value) => {
        const stringValue = String(value);
        const parts = stringValue.split(',');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join(',');
    };

    const setTableData = () => {

        let rowsData = [];
        let tempData = yearlyAverage;
        let year1 = tempData.find(year => year.year === "year_1") || { avgExposure: 0, creditLossSum: 0, operationalCostsSum: 0, incomeSum: 0, otherIncomeSum: 0, fundingCostSum: 0, seniorCostSum: 0, subDebtCostSum: 0, equityCostSum: 0, year: "year_1" };
        let year2 = tempData.find(year => year.year === "year_2") || { avgExposure: 0, creditLossSum: 0, operationalCostsSum: 0, incomeSum: 0, otherIncomeSum: 0, fundingCostSum: 0, seniorCostSum: 0, subDebtCostSum: 0, equityCostSum: 0, year: "year_2" };
        let year3 = tempData.find(year => year.year === "year_3") || { avgExposure: 0, creditLossSum: 0, operationalCostsSum: 0, incomeSum: 0, otherIncomeSum: 0, fundingCostSum: 0, seniorCostSum: 0, subDebtCostSum: 0, equityCostSum: 0, year: "year_3" };
        let year4 = tempData.find(year => year.year === "year_4") || { avgExposure: 0, creditLossSum: 0, operationalCostsSum: 0, incomeSum: 0, otherIncomeSum: 0, fundingCostSum: 0, seniorCostSum: 0, subDebtCostSum: 0, equityCostSum: 0, year: "year_4" };
        let year5 = tempData.find(year => year.year === "year_5") || { avgExposure: 0, creditLossSum: 0, operationalCostsSum: 0, incomeSum: 0, otherIncomeSum: 0, fundingCostSum: 0, seniorCostSum: 0, subDebtCostSum: 0, equityCostSum: 0, year: "year_5" };
        let year6 = tempData.find(year => year.year === "year_6") || { avgExposure: 0, creditLossSum: 0, operationalCostsSum: 0, incomeSum: 0, otherIncomeSum: 0, fundingCostSum: 0, seniorCostSum: 0, subDebtCostSum: 0, equityCostSum: 0, year: "year_6" };
        let total = tempData.find(year => year.year === "Total") || { avgExposure: 0, creditLossSum: 0, operationalCostsSum: 0, incomeSum: 0, otherIncomeSum: 0, fundingCostSum: 0, seniorCostSum: 0, subDebtCostSum: 0, equityCostSum: 0, year: "Total" };

        rowsData = [
            {
                [` `]: "Average NIL",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.avgExposure))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.avgExposure))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.avgExposure))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.avgExposure))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.avgExposure))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.avgExposure))}`,
                Total: `$${handleIntInputChange(Math.round(total.avgExposure))}`,
            },
            {
                [` `]: "Income",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.incomeSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.incomeSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.incomeSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.incomeSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.incomeSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.incomeSum))}`,
                Total: `$${handleIntInputChange(Math.round(year1.incomeSum + year2.incomeSum + year3.incomeSum +year4.incomeSum + year5.incomeSum + year6.incomeSum))}`,
            },
            {
                [` `]: "Other Income",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.otherIncomeSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.otherIncomeSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.otherIncomeSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.otherIncomeSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.otherIncomeSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.otherIncomeSum))}`,
                Total: `$${handleIntInputChange(Math.round(year1.otherIncomeSum + year2.otherIncomeSum + year3.otherIncomeSum +year4.otherIncomeSum + year5.otherIncomeSum + year6.otherIncomeSum))}`,
            },
            {
                [` `]: "Total Income",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.incomeSum + year1.otherIncomeSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.incomeSum + year2.otherIncomeSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.incomeSum + year3.otherIncomeSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.incomeSum + year4.otherIncomeSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.incomeSum + year5.otherIncomeSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.incomeSum + year6.otherIncomeSum))}`,
                Total: `$${handleIntInputChange(Math.round(total.incomeSum + total.otherIncomeSum))}`
            },
            // {
            //     [` `]: "Income %",
            //     [`Year 1`]: `${(((year1.incomeSum + year1.otherIncomeSum)/year1.avgExposure)*100).toFixed(2)}%`,
            //     [`Year 2`]: year2.avgExposure !== 0 ? `${(((year2.incomeSum + year2.otherIncomeSum)/year2.avgExposure)*100).toFixed(2)}%` : `0%`,
            //     [`Year 3`]: year3.avgExposure !== 0 ? `${(((year3.incomeSum + year3.otherIncomeSum)/year3.avgExposure)*100).toFixed(2)}%` : `0%`,
            //     [`Year 4`]: year4.avgExposure !== 0 ? `${(((year4.incomeSum + year4.otherIncomeSum)/year4.avgExposure)*100).toFixed(2)}%` : `0%`,
            //     [`Year 5`]: year5.avgExposure !== 0 ? `${(((year5.incomeSum + year5.otherIncomeSum)/year5.avgExposure)*100).toFixed(2)}%` : `0%`,
            //     [`Year 6`]: year6.avgExposure !== 0 ? `${(((year6.incomeSum + year6.otherIncomeSum)/year6.avgExposure)*100).toFixed(2)}%` : `0%`,
            //     Total: `${(((total.incomeSum + total.otherIncomeSum)/total.avgExposure)*100).toFixed(2)}%`
            // },
            {
                [` `]: "Credit costs",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.creditLossSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.creditLossSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.creditLossSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.creditLossSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.creditLossSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.creditLossSum))}`,
                Total: `$${handleIntInputChange(Math.round(total.creditLossSum))}`
            },
            {
                [` `]: "Operational costs",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.operationalCostsSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.operationalCostsSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.operationalCostsSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.operationalCostsSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.operationalCostsSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.operationalCostsSum))}`,
                Total: `$${handleIntInputChange(Math.round(total.operationalCostsSum))}`
            },
            {
                [` `]: "Funding costs (WACC)",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.fundingCostSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.fundingCostSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.fundingCostSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.fundingCostSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.fundingCostSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.fundingCostSum))}`,
                Total: `$${handleIntInputChange(Math.round(total.fundingCostSum))}`
            },
            {
                [` `]: "Senior",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.seniorCostSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.seniorCostSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.seniorCostSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.seniorCostSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.seniorCostSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.seniorCostSum))}`,
                Total: `$${handleIntInputChange(Math.round(total.seniorCostSum))}`
            },
            {
                [` `]: "Subdebt",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.subDebtCostSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.subDebtCostSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.subDebtCostSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.subDebtCostSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.subDebtCostSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.subDebtCostSum))}`,
                Total: `$${handleIntInputChange(Math.round(total.subDebtCostSum))}`
            },
            {
                [` `]: "Equity",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.equityCostSum))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round(year2.equityCostSum))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round(year3.equityCostSum))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round(year4.equityCostSum))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round(year5.equityCostSum))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round(year6.equityCostSum))}`,
                Total: `$${handleIntInputChange(Math.round(total.equityCostSum))}`
            },
            {
                [` `]: "Total Cost",
                [`Year 1`]: `$${handleIntInputChange(Math.round((year1.creditLossSum + year1.operationalCostsSum + year1.fundingCostSum)))}`,
                [`Year 2`]: `$${handleIntInputChange(Math.round((year2.creditLossSum + year2.operationalCostsSum + year2.fundingCostSum)))}`,
                [`Year 3`]: `$${handleIntInputChange(Math.round((year3.creditLossSum + year3.operationalCostsSum + year3.fundingCostSum)))}`,
                [`Year 4`]: `$${handleIntInputChange(Math.round((year4.creditLossSum + year4.operationalCostsSum + year4.fundingCostSum)))}`,
                [`Year 5`]: `$${handleIntInputChange(Math.round((year5.creditLossSum + year5.operationalCostsSum + year5.fundingCostSum)))}`,
                [`Year 6`]: `$${handleIntInputChange(Math.round((year6.creditLossSum + year6.operationalCostsSum + year6.fundingCostSum)))}`,
                Total: `$${handleIntInputChange(Math.round(total.creditLossSum + total.operationalCostsSum + total.fundingCostSum))}`
            },
            // {
            //     [` `]: "Costs %",
            //     [`Year 1`]: `${(((year1.creditLossSum + year1.operationalCostsSum + year1.fundingCostSum)/year1.avgExposure)*100).toFixed(2)}%`,
            //     [`Year 2`]: year2.avgExposure !== 0 ? `${(((year2.creditLossSum + year2.operationalCostsSum + year2.fundingCostSum)/year2.avgExposure) *100).toFixed(2)}%` : `0%`,
            //     [`Year 3`]: year3.avgExposure !== 0 ? `${(((year3.creditLossSum + year3.operationalCostsSum + year3.fundingCostSum)/year3.avgExposure) *100).toFixed(2)}%` : `0%`,
            //     [`Year 4`]: year4.avgExposure !== 0 ? `${(((year4.creditLossSum + year4.operationalCostsSum + year4.fundingCostSum)/year4.avgExposure) *100).toFixed(2)}%` : `0%`,
            //     [`Year 5`]: year5.avgExposure !== 0 ? `${(((year5.creditLossSum + year5.operationalCostsSum + year5.fundingCostSum)/year5.avgExposure) *100).toFixed(2)}%` : `0%`,
            //     [`Year 6`]: year6.avgExposure !== 0 ? `${(((year6.creditLossSum + year6.operationalCostsSum + year6.fundingCostSum)/year6.avgExposure) *100).toFixed(2)}%` : `0%`,
            //     Total: `${(((total.creditLossSum + total.operationalCostsSum + total.operationalCostsSum)/total.avgExposure) *100).toFixed(2)}%`,
            // },
            {
                [` `]: "Net Profit",
                [`Year 1`]: `$${handleIntInputChange(Math.round(((year1.incomeSum + year1.otherIncomeSum)-(year1.creditLossSum + year1.operationalCostsSum + year1.fundingCostSum) + year1.equityCostSum)))}`,
                [`Year 2`]: year2.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year2.incomeSum + year2.otherIncomeSum) - (year2.creditLossSum + year2.operationalCostsSum + year2.fundingCostSum) + year2.equityCostSum )))}` : '$0',
                [`Year 3`]: year3.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year3.incomeSum + year3.otherIncomeSum) - (year3.creditLossSum + year3.operationalCostsSum + year3.fundingCostSum) + year3.equityCostSum )))}` : '$0',
                [`Year 4`]: year4.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year4.incomeSum + year4.otherIncomeSum) - (year4.creditLossSum + year4.operationalCostsSum + year4.fundingCostSum) + year4.equityCostSum )))}` : '$0',
                [`Year 5`]: year5.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year5.incomeSum + year5.otherIncomeSum) - (year5.creditLossSum + year5.operationalCostsSum + year5.fundingCostSum) + year5.equityCostSum )))}` : '$0',
                [`Year 6`]: year6.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year6.incomeSum + year6.otherIncomeSum) - (year6.creditLossSum + year6.operationalCostsSum + year6.fundingCostSum) + year6.equityCostSum )))}` : '$0',
                Total: `$${handleIntInputChange(Math.round((total.incomeSum+total.otherIncomeSum)-(total.creditLossSum + total.operationalCostsSum + total.fundingCostSum) + total.equityCostSum))}`,
            },
            {
                [` `]: "NP %",
                [`Year 1`]: `${((((year1.incomeSum + year1.otherIncomeSum)-(year1.creditLossSum + year1.operationalCostsSum + year1.fundingCostSum)+year1.equityCostSum)/year1.avgExposure)*100).toFixed(2)}%`,
                [`Year 2`]: year2.avgExposure !== 0 ? `${((((year2.incomeSum + year2.otherIncomeSum) - (year2.creditLossSum + year2.operationalCostsSum + year2.fundingCostSum) + year2.equityCostSum)/year2.avgExposure)*100).toFixed(2)}%` : `0%`,
                [`Year 3`]: year3.avgExposure !== 0 ? `${((((year3.incomeSum + year3.otherIncomeSum) - (year3.creditLossSum + year3.operationalCostsSum + year3.fundingCostSum) + year3.equityCostSum)/year3.avgExposure)*100).toFixed(2)}%` : `0%`,
                [`Year 4`]: year4.avgExposure !== 0 ? `${((((year4.incomeSum + year4.otherIncomeSum) - (year4.creditLossSum + year4.operationalCostsSum + year4.fundingCostSum) + year4.equityCostSum)/year4.avgExposure)*100).toFixed(2)}%` : `0%`,
                [`Year 5`]: year5.avgExposure !== 0 ? `${((((year5.incomeSum + year5.otherIncomeSum) - (year5.creditLossSum + year5.operationalCostsSum + year5.fundingCostSum) + year5.equityCostSum)/year5.avgExposure)*100).toFixed(2)}%` : `0%`,
                [`Year 6`]: year6.avgExposure !== 0 ? `${((((year6.incomeSum + year6.otherIncomeSum) - (year6.creditLossSum + year6.operationalCostsSum + year6.fundingCostSum) + year6.equityCostSum)/year6.avgExposure)*100).toFixed(2)}%` : `0%`,
                Total: `${((((total.incomeSum+total.otherIncomeSum)-(total.creditLossSum + total.operationalCostsSum + total.fundingCostSum) + total.equityCostSum)/total.avgExposure)*100).toFixed(2)}%`,
            },
            {
                [` `]: "Profit above Equity hurdle",
                [`Year 1`]: `$${handleIntInputChange(Math.round(((year1.incomeSum + year1.otherIncomeSum)-(year1.creditLossSum + year1.operationalCostsSum + year1.fundingCostSum))))}`,
                [`Year 2`]: year2.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year2.incomeSum + year2.otherIncomeSum) - (year2.creditLossSum + year2.operationalCostsSum + year2.fundingCostSum))))}` : '$0',
                [`Year 3`]: year3.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year3.incomeSum + year3.otherIncomeSum) - (year3.creditLossSum + year3.operationalCostsSum + year3.fundingCostSum))))}` : '$0',
                [`Year 4`]: year4.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year4.incomeSum + year4.otherIncomeSum) - (year4.creditLossSum + year4.operationalCostsSum + year4.fundingCostSum))))}` : '$0',
                [`Year 5`]: year5.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year5.incomeSum + year5.otherIncomeSum) - (year5.creditLossSum + year5.operationalCostsSum + year5.fundingCostSum))))}` : '$0',
                [`Year 6`]: year6.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(((year6.incomeSum + year6.otherIncomeSum) - (year6.creditLossSum + year6.operationalCostsSum + year6.fundingCostSum))))}` : '$0',
                Total: `$${handleIntInputChange(Math.round((total.incomeSum+total.otherIncomeSum)-(total.creditLossSum + total.operationalCostsSum + total.fundingCostSum)))}`,
            },
            {
                [` `]: "Profit above Equity hurdle %",
                [`Year 1`]: `${((((year1.incomeSum + year1.otherIncomeSum)-(year1.creditLossSum + year1.operationalCostsSum + year1.fundingCostSum))/year1.avgExposure)*100).toFixed(2)}%`,
                [`Year 2`]: year2.avgExposure !== 0 ? `${((((year2.incomeSum + year2.otherIncomeSum) - (year2.creditLossSum + year2.operationalCostsSum + year2.fundingCostSum))/year2.avgExposure)*100).toFixed(2)}%` : `0%`,
                [`Year 3`]: year3.avgExposure !== 0 ? `${((((year3.incomeSum + year3.otherIncomeSum) - (year3.creditLossSum + year3.operationalCostsSum + year3.fundingCostSum))/year3.avgExposure)*100).toFixed(2)}%` : `0%`,
                [`Year 4`]: year4.avgExposure !== 0 ? `${((((year4.incomeSum + year4.otherIncomeSum) - (year4.creditLossSum + year4.operationalCostsSum + year4.fundingCostSum))/year4.avgExposure)*100).toFixed(2)}%` : `0%`,
                [`Year 5`]: year5.avgExposure !== 0 ? `${((((year5.incomeSum + year5.otherIncomeSum) - (year5.creditLossSum + year5.operationalCostsSum + year5.fundingCostSum))/year5.avgExposure)*100).toFixed(2)}%` : `0%`,
                [`Year 6`]: year6.avgExposure !== 0 ? `${((((year6.incomeSum + year6.otherIncomeSum) - (year6.creditLossSum + year6.operationalCostsSum + year6.fundingCostSum))/year6.avgExposure)*100).toFixed(2)}%` : `0%`,
                Total: `${((((total.incomeSum+total.otherIncomeSum)-(total.creditLossSum + total.operationalCostsSum + total.fundingCostSum))/total.avgExposure)*100).toFixed(2)}%`,
            },
            {
                [` `]: "Capital from Equity",
                [`Year 1`]: `$${handleIntInputChange(Math.round(year1.avgExposure * equity.capitalPer))}`,
                [`Year 2`]: year2.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(year2.avgExposure * equity.capitalPer))}` : '$0',
                [`Year 3`]: year3.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(year3.avgExposure * equity.capitalPer))}` : '$0',
                [`Year 4`]: year4.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(year4.avgExposure * equity.capitalPer))}` : '$0',
                [`Year 5`]: year5.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(year5.avgExposure * equity.capitalPer))}` : '$0',
                [`Year 6`]: year6.avgExposure !== 0 ? `$${handleIntInputChange(Math.round(year6.avgExposure * equity.capitalPer))}` : '$0',
                Total: `$${handleIntInputChange(Math.round((year1.avgExposure * equity.capitalPer) + (year2.avgExposure * equity.capitalPer) + (year3.avgExposure * equity.capitalPer) + (year4.avgExposure * equity.capitalPer) + (year5.avgExposure * equity.capitalPer) + (year6.avgExposure * equity.capitalPer)))}`,
            },
            {
                [` `]: "ROE %",
                [`Year 1`]: `${((year1.equityCostSum/(year1.avgExposure * equity.capitalPer))*100).toFixed(2)}%`,
                [`Year 2`]: year2.avgExposure !== 0 ? `${((year2.equityCostSum/(year2.avgExposure * equity.capitalPer))*100).toFixed(2)}%` : `0%`,
                [`Year 3`]: year3.avgExposure !== 0 ? `${((year3.equityCostSum/(year3.avgExposure * equity.capitalPer))*100).toFixed(2)}%` : `0%`,
                [`Year 4`]: year4.avgExposure !== 0 ? `${((year4.equityCostSum/(year4.avgExposure * equity.capitalPer))*100).toFixed(2)}%` : `0%`,
                [`Year 5`]: year5.avgExposure !== 0 ? `${((year5.equityCostSum/(year5.avgExposure * equity.capitalPer))*100).toFixed(2)}%` : `0%`,
                [`Year 6`]: year6.avgExposure !== 0 ? `${((year6.equityCostSum/(year6.avgExposure * equity.capitalPer))*100).toFixed(2)}%` : `0%`,
                Total: `${((total.equityCostSum/((year1.avgExposure * equity.capitalPer) + (year2.avgExposure * equity.capitalPer) + (year3.avgExposure * equity.capitalPer) + (year4.avgExposure * equity.capitalPer) + (year5.avgExposure * equity.capitalPer) + (year6.avgExposure * equity.capitalPer)))*100).toFixed(2)}%`
            }
        ]
        rows = rowsData;
        // console.log(rows);
        const totalPer = rows.find(variables => variables[` `] === "Profit above Equity hurdle %")
        totalPercentage = totalPer.Total.split("%")[0];
        // console.log(totalPercentage);
        const graphDataTemp = exposureData.map(({ exposure }) => ({ exposure }));
        // console.log(graphDataTemp);
        graphData = graphDataTemp;

    }

    const getValueLines = () => {
        try {
            for (let i=0; i<=10; i++){
                let totalValueForYear = 0;
                let allResultsFound = true;

                for (const asset of assets) {
                    const lookupValue = asset.assetType + (asset.ageEquip + i).toString();
                    const result = lookupData.find(item => item.key === lookupValue);

                    if (result) {
                        totalValueForYear += result.valueLine;
                    } else {
                        allResultsFound = false;
                        break;
                    }
                }

                if (allResultsFound) {
                    valueLines.push({
                        key: i,
                        year: `Year ${i}`,
                        value: totalValueForYear
                    });
                } else {
                    return '0%';
                }
            }
            // console.log("valueLines: ");
            // console.log(valueLines);
        } catch (error) {
            console.error('An error occurred during the lookup:', error);
            return '0%';
        }
    }

    const getAveExp = (tempData) => {
        let year = 0;
        let startIndex = tempData.findIndex((item) => item.time === 1);

        for (let i = startIndex; i < tempData.length; i += 12) {
            const exposureSet = tempData.slice(i, i + 12);
            const avgExposure = exposureSet?.reduce((sum, item) => sum + item.exposure, 0) / exposureSet.length;
            const creditLossSum = exposureSet?.reduce((sum, item) => sum + item.creditLoss, 0);
            const operationalCostsSum = exposureSet?.reduce((sum, item) => sum + item.operationalCosts, 0) /12;
            const incomeSum = exposureSet?.reduce((sum, item) => sum + item.income, 0) / 12;
            const otherIncomeSum = exposureSet?.reduce((sum, item) => sum + item.otherIncome, 0) / 12;
            const fundingCostSum = exposureSet?.reduce((sum, item) => sum + item.fundingCost, 0);
            const seniorSum = exposureSet?.reduce((sum, item) => sum + item.senior, 0);
            const subdebtSum = exposureSet?.reduce((sum, item) => sum + item.subdebt, 0);
            const equitySum = exposureSet?.reduce((sum, item) => sum + item.equity, 0);
            let yearlyAvg = {
                year: `year_${year+1}`,
                avgExposure: avgExposure,
                creditLossSum: creditLossSum,
                operationalCostsSum: operationalCostsSum,
                incomeSum: incomeSum,
                otherIncomeSum: otherIncomeSum,
                fundingCostSum: fundingCostSum,
                seniorCostSum: seniorSum,
                subDebtCostSum: subdebtSum,
                equityCostSum: equitySum,
            };
            year++;
            yearlyAverage.push(yearlyAvg);
        }
        if (yearlyAverage.length > 0){
            let totalCreditLossSum = 0;
            let totalOperationalCostsSum = 0;
            let totalIncomeSum = 0;
            let totalOtherIncomeSum = 0;
            let totalfundingCostSum = 0;
            let totalseniorSum = 0;
            let totalsubdebtSum = 0;
            let totalequitySum = 0;
            let totalAvgExposure = nil;

            for (const obj of yearlyAverage) {
                totalCreditLossSum += obj.creditLossSum;
                totalOperationalCostsSum += obj.operationalCostsSum;
                totalIncomeSum += obj.incomeSum;
                totalOtherIncomeSum += obj.otherIncomeSum;
                totalfundingCostSum += obj.fundingCostSum;
                totalseniorSum += obj.seniorCostSum;
                totalsubdebtSum += obj.subDebtCostSum;
                totalequitySum += obj.equityCostSum;
            }
            let total = {
                year: "Total",
                avgExposure: totalAvgExposure,
                creditLossSum: totalCreditLossSum,
                operationalCostsSum: totalOperationalCostsSum,
                incomeSum: totalIncomeSum,
                otherIncomeSum: totalOtherIncomeSum,
                fundingCostSum: totalfundingCostSum,
                seniorCostSum: totalseniorSum,
                subDebtCostSum: totalsubdebtSum,
                equityCostSum: totalequitySum,
            };
            yearlyAverage.push(total);
        }
        // console.log("yearlyAverage");
        // console.log(yearlyAverage);
    }

    const getCalculations = () => {
        getValueLines();
        const adjValLines = adjValueLines;
        let exposureVal = nil;
        let reduceExp = null;
        let exposureNoRef = null;
        let month = 1;
        let valueLinePer = 0;
        let valueLine = null;
        let creditLoss = null;
        let operationalCosts = null;
        let income = null;
        let otherIncome = null;
        let fundingCostWacc = null;
        // console.log("adj Value Line");
        // console.log(adjValLines);
        for (let i=1; i<=time; i++) {
            if (i <= time){
                // console.log(`annualPerc: ${annualRate}`)
                const monthsRemaining = (12 - month) / 12;
                const monthsPassed = month / 12;
                const roundedDownIndex = Math.floor(i / 12);
                const roundedUpIndex = Math.ceil(i / 12);
                const roundedDownPercentage = valueLines[roundedDownIndex].value;
                const roundedUpPercentage = valueLines[roundedUpIndex].value;
                valueLinePer = monthsRemaining * (roundedDownPercentage/100) + monthsPassed * (roundedUpPercentage/100);
                // console.log(`equipCost: ${equipCost} *`)
                // console.log(`valueLinePer: ${valueLinePer} =`)
                // valueLine = equipCost*valueLinePer;
                valueLine = adjValLines[i-1];
                // console.log(`valueLine: ${valueLine}`)
                // console.log(`exposureVal: ${exposureVal}`)
                // console.log(`creditExp: ${creditExp}`)
                // console.log(`annualRate: ${annualRate}`);
                income = i === 1 ? 0 : exposureVal*annualRate;
                exposureVal = i > 1 ? (exposureVal*(1+(annualRate/12)))-paymentAmount : nil;
                let creditExp = exposureVal-valueLine > 0 ? exposureVal-valueLine : 0;
                operationalCosts = exposureVal * opCosts;
                creditLoss = (riskClassPd/18) * creditExp;
                otherIncome = exposureVal*otherCosts;
                fundingCostWacc = exposureVal*(wacc/12);
                let seniorVal = fundingCostWacc * senior.returnWacc;
                let subdebtVal = fundingCostWacc * subdebt.returnWacc;
                let equityVal = fundingCostWacc * equity.returnWacc;
                reduceExp = paymentAmount-income;
                exposureNoRef = i > 1 ? exposureNoRef - reduceExp : nil;
                let decimalPart = 0;
                let monthData = {
                    time: i,
                    exposure: (decimalPart = exposureVal % 1) < 0.55 ? Math.floor(exposureVal) : Math.ceil(exposureVal),
                    reduceExposure: (decimalPart = reduceExp % 1) < 0.55 ? Math.floor(reduceExp) : Math.ceil(reduceExp),
                    exposureNoRef: (decimalPart = exposureNoRef % 1) < 0.55 ? Math.floor(exposureNoRef) : Math.ceil(exposureNoRef),
                    month: month,
                    valueLinePer: parseInt((valueLinePer*100).toFixed(0)),
                    valueLine: valueLine,
                    creditLoss: creditLoss,
                    operationalCosts: operationalCosts,
                    income: income,
                    otherIncome: otherIncome,
                    fundingCost: fundingCostWacc,
                    senior: seniorVal,
                    subdebt: subdebtVal,
                    equity: equityVal
                }
                exposureData.push(monthData);
                month < 11 ? month++ : month = 0;
            }
        }
        // console.log("exposureData");
        // console.log(exposureData);
        getAveExp(exposureData);
        setTableData();
    }


    if (infoData){
        const tempInfoData = infoData;
        tempInfoData.map(section => {
            section.name === "riskClassPd" ? riskClassPd = section.value : null;
            section.name === "minTargetProfit" ? minTargetProfit = section.value : null;
            section.name === "fundingCosts" ? fundingCosts = section.value : null;
            section.name === "opCosts" ? opCosts = section.value : null;
            section.name === "otherCosts" ? otherCosts = section.value : null;
            section.name === "originalNil" ? originalNil = section.value : null;
            section.name === "originalDownPay" ? originalDownPay = section.value : null;
            section.name === "nilVal" ? nil = section.value : null;
            section.name === "doPay" ? downPay = section.value : null;
            section.name === "paymentAmount" ? paymentAmount = section.value : null;
            section.name === "periods" ? time = section.value : null;
            section.name === "equipCost" ? equipCost = section.value : null;
            section.name === "annualRate" ? annualRate = section.value : null;
            section.name === "fundingCostsWacc" ? wacc = section.value : null;
            section.name === "senior" ? senior = section.value : null;
            section.name === "subdebt" ? subdebt = section.value : null;
            section.name === "equity" ? equity = section.value : null;
        });
        // annualPer = (annualRate/12)/100;
        getCalculations();
    }

    return {
        dealColumns: [
            { name: " ", align: "left"},
            { name: "Year 1", align: "center"},
            { name: "Year 2", align: "center"},
            { name: "Year 3", align: "center"},
            { name: "Year 4", align: "center"},
            { name: "Year 5", align: "center"},
            { name: "Year 6", align: "center"},
            { name: "Total", align: "center"},
        ],
        dealRows: rows,
        graphData: graphData,
        totalPercentage: totalPercentage
    }
}